<template>
  <div class="component-transaction-view-predefined-selections-all-transfers-table">

    <b-row no-gutters>
      <p class="col-md-8">{{ $t(translationPath + 'description', { period_from: startDate, period_end: endDate}) }}</p>
    </b-row>
    <b-row no-gutters v-show="multipleSelectedRows.length > 0" class="print-none">
      <b-col cols="12" class="float-left px-0 mt-2 actionButtons">
        <b-button variant="outline-danger"  class="btn-sm" @click="$refs.DeleteModalMultiple.show()"><i class="flaticon stroke trash-3 mr-2"></i>{{$t(translationPath + 'btn_delete_selected_transactions')}}</b-button>
      </b-col>
    </b-row>

    <b-table class="spirecta-simple-table mt-3" show-empty hover responsive
             striped
             stacked="md"
             ref="TransfersTable"
             :selectable="true"
             :emptyText="$t(translationPath + 'table.no_data')"
             :busy="busyState"
             :items="transfers"
             :fields="tableFields"
             :filter="sTableFilter"
             :filter-included-fields="['title']"
             @row-selected="onRowSelected"
    >
      <!-- LOADER -->
      <template v-slot:table-busy>
        <loader />
      </template>

      <!-- FILTER -->
      <template slot="top-row">
        <td :colspan="(tableFields.length)">
          <div class="d-flex align-items-center">
            <i class="fa fa-search text-secondary"></i>
            <b-form-input v-model="sTableFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
          </div>
        </td>
      </template>

      <!-- head:checkbox -->
      <template v-slot:head(is_checked)>
        <b-form-checkbox v-model="allSelected" :indeterminate="indeterminate" @change="toggleAll"></b-form-checkbox>
      </template>

      <template v-slot:head(account_title)>
          {{ $t(currentUser.strict_accounting_mode ? 'common.account' : 'common.category') }}
      </template>

      <!-- checkbox -->
      <template v-slot:cell(is_checked)="row">
        <b-form-checkbox-group v-model="multipleSelectedRows">
          <b-form-checkbox :value="row.item" @change="selectCheckBoxRow(row.rowSelected, row.index)"></b-form-checkbox>
        </b-form-checkbox-group>
      </template>

      <!-- date -->
      <template v-slot:cell(date)="row">
        {{ row.value }}
      </template>

      <!-- title -->
      <template v-slot:cell(title)="row">
        {{ row.value }}
      </template>

      <!-- amount -->
      <template v-slot:cell(amount)="row">
        {{ (row.value) | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso,true,0) }}
      </template>

      <!-- account_title -->
      <template v-slot:cell(account_title)="row">
        <i18n :path="'common.transaction_subtypes.'+row.item.transaction_subtype" tag="span" style="font-size:90%">
          <template v-slot:cr_account v-if="Object.prototype.hasOwnProperty.call(row.item, 'cr_entries') && ( 0 in row.item.cr_entries)">
            <b-link :to="'/reports/performance/accounts/'+row.item.cr_entries[0].account_id+'/view'">{{ row.item.cr_entries[0].account_title }}</b-link>
          </template>
          <template v-slot:dr_account v-if="Object.prototype.hasOwnProperty.call(row.item, 'dr_entries') && ( 0 in row.item.dr_entries)">
            <b-link :to="'/reports/performance/accounts/'+row.item.dr_entries[0].account_id+'/view'">{{ row.item.dr_entries[0].account_title }}</b-link>
          </template>
        </i18n>
      </template>

      <!-- actions -->
      <template v-slot:cell(actions)="row">
        <button class="btn plain-btn text-regular action-button" @click="onDeleteItem(row.item)">
          <i class="flaticon solid trash-3 text-secondary"></i>{{ $t("common.delete") }}
        </button>
      </template>
    </b-table>

    <delete-modal
      ref="DeleteModalSingle"
      :heading="$t(translationPath + 'delete_modal_single.title').toString()"
      :message="$t(translationPath + 'delete_modal_single.message', { title: itemToDelete ? itemToDelete.title : '' }).toString()"
      @on-delete-cancel="itemToDelete = null"
      @on-delete-confirm="deleteItem"
    />

    <delete-modal
      ref="DeleteModalMultiple"
      :heading="$t(translationPath + 'delete_modal_multiple.title').toString()"
      :message="$t(translationPath + 'delete_modal_multiple.message').toString()"
      @on-delete-confirm="deleteItems"
    />
  </div>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import DeleteModal from '@/components/modals/DeleteModal'

export default {
  name: 'TransactionsViewPredefinedSelectionsAllTransfersTable',
  components: { Loader, DeleteModal },
  props: ['startDate', 'endDate'],
  filters: { formatAmount },
  data () {
    return {
      translationPath: 'transactions.view-v2.predefined_selections.all_transfers.',
      transfers: [],
      busyState: false,
      sTableFilter: '',
      multipleSelectedRows: [],
      itemToDelete: null,
      allSelected: false
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    tableFields () {
      return [
        { key: 'is_checked', label: '' },
        { key: 'date', label: this.$t('common.date'), sortable: true },
        { key: 'title', label: this.$t('common.title'), sortable: true },
        { key: 'amount', label: this.$t('common.amount'), sortable: true },
        { key: 'account_title', label: this.$t('common.transaction_subtype'), class: 'td-account-title' },
        { key: 'actions', label: '', class: 'text-center text-md-right td-actions' }
      ]
    },
    indeterminate () {
      if (this.multipleSelectedRows.length && this.multipleSelectedRows.length !== this.transfers.length) {
        return true
      }
      return false
    }
  },
  methods: {
    applyFilter () {
      this.busyState = true
      axios.get(`${process.env.VUE_APP_ROOT_API}/transactions/with/subtype_group?transaction_type_group=transfer_transactions&start_date=${this.startDate}&end_date=${this.endDate}`)
        .then(response => {
          this.transfers = response.data.data[0]
          this.busyState = false
        })
        .catch(err => {
          console.error(err)
        })
    },
    selectCheckBoxRow (rowSelected, index) {
      if (rowSelected) {
        this.$refs.TransfersTable.unselectRow(index)
      } else {
        this.$refs.TransfersTable.selectRow(index)
      }
      if (!this.multipleSelectedRows.length) {
        this.allSelected = false
      } else if (this.multipleSelectedRows.length === this.transfers.length) {
        this.allSelected = true
      }
    },
    onRowSelected (items) {
      this.multipleSelectedRows = items
      if (!this.indeterminate && this.multipleSelectedRows.length) {
        this.allSelected = true
      }
    },
    toggleAll (checked) {
      this.multipleSelectedRows = []
      if (checked) {
        this.transfers.map((el, i) => {
          this.$refs.TransfersTable.selectRow(i)
          this.multipleSelectedRows.push(el)
        })
      } else {
        this.transfers.map((el, i) => {
          this.$refs.TransfersTable.unselectRow(i)
        })
      }
    },
    onDeleteItem (item) {
      this.itemToDelete = item
      this.$refs.DeleteModalSingle.show()
    },
    deleteItem () {
      const delData = { transaction_ids: [this.itemToDelete.id] }
      axios.delete(`${process.env.VUE_APP_ROOT_API}/transactions/with/entries`, { data: delData })
        .then(response => {
          this.$bvToast.toast(this.$t(this.translationPath + 'delete_modal_single.toast_success', { title: this.itemToDelete.title }).toString(), {
            title: this.$t(this.translationPath + 'delete_modal_single.title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.transfers = this.transfers.filter(el => el.id !== this.itemToDelete.id)
          this.multipleSelectedRows = []
          this.allSelected = false
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'delete_modal_single.toast_fail', { title: this.itemToDelete.title }).toString(), {
            title: this.$t(this.translationPath + 'delete_modal_single.title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .then(() => {
          this.itemToDelete = null
        })
    },
    deleteItems () {
      const delData = { transaction_ids: [] }
      this.multipleSelectedRows.map(el => {
        delData.transaction_ids.push(el.id)
      })
      axios.delete(`${process.env.VUE_APP_ROOT_API}/transactions/with/entries`, { data: delData })
        .then(response => {
          this.$bvToast.toast(this.$t(this.translationPath + 'delete_modal_multiple.toast_success').toString(), {
            title: this.$t(this.translationPath + 'delete_modal_multiple.title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.transfers = this.transfers.filter(el => delData.transaction_ids.indexOf(el.id) === -1)
          this.multipleSelectedRows = []
          this.allSelected = false
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'delete_modal_multiple.toast_fail').toString(), {
            title: this.$t(this.translationPath + 'delete_modal_multiple.title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    }
  },
  watch: {
    startDate: {
      handler () {
        this.applyFilter()
      },
      immediate: true,
      deep: true
    },
    endDate: {
      handler () {
        this.applyFilter()
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
.component-transaction-view-predefined-selections-all-transfers-table {
  .td-account-title {
    * {
      color: #84888f;
    }
    a {
      text-decoration: underline;
      text-decoration-style: dotted;
    }
  }
  .td-actions {
    font-size: 90%;
  }
}
</style>
