export default {
  name: 'TransactionsViewMixin',
  props: {
  },
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    /**
     * Convert filter params to query string used for API-endpoint
     * @returns {string}
     */
    createParamStringFromFilterParams (aFilterParams, paramKey = null, paramValue = null) {
      let sFilterString = ''

      if (aFilterParams !== null) {
        // if params are set replace value to be able to e.g. be able to self-reference, see custom slot:import
        if (paramKey !== null && paramValue !== null) {
          aFilterParams.paramKey = paramValue
        }

        for (const idx in aFilterParams) {
          if (aFilterParams[idx] !== null && aFilterParams[idx] !== '') {
            sFilterString += '&' + idx + '=' + aFilterParams[idx]
          }
        }
      } else if (paramKey !== null && paramValue !== null) {
        sFilterString = '&' + paramKey + '=' + paramValue
      }
      return sFilterString
    }
  }
}
