<template>
  <div class="component-transaction-view-predefined-selection-potential-duplicates">

    <template v-if="busyState === false && Object.keys(duplicatesItems).length == 0">
      <b-alert show variant="success">
        <div v-html="$t(pathTranslation + 'no_potential_duplicates_found',{ period_from: startDate, period_end: endDate})"></div>
      </b-alert>
    </template>

    <b-row no-gutters v-show="multipleSelectedRows.length > 0" class="print-none">
      <b-col cols="12" class="text-right px-0 mt-2 actionButtons">
        <b-button variant="outline-danger" class="btn-sm mb-2" @click="$refs.deleteOverview.show()"><i class="flaticon stroke trash-3 mr-2"></i>{{$t('common.duplicate_delete_button')}}</b-button>
      </b-col>
    </b-row>
    <template v-if="busyState === false && Object.keys(duplicatesItems).length > 0">
      <b-table
        class="spirecta-simple-table assets-table"
        :items="duplicatesItems"
        :fields="fieldValues"
        :emptyText="$t('common.no_data')"
        stacked="md"
        :busy="busyState"
        show-empty
        @row-selected="onRowSelected"
        ref="DuplicateOverview"
      >
        <!--loader-->
        <template v-slot:table-busy>
          <loader />
        </template>

        <!-- date -->
        <template v-slot:cell(date)="row">
          {{ $moment(row.value).format("YYYY-MM-DD") }}
        </template>

        <!-- title -->
        <template v-slot:cell(title)="row">
          <b-link @click="createToLink(row.value)">{{ row.value }}</b-link>
        </template>

        <!-- amount -->
        <template v-slot:cell(amount)="row">
            <span :class="`font-weight-bolder transaction_${row.item.subtype}`">
              {{formatAmt(row.value) }}
            </span>
        </template>

        <!-- original -->
        <template v-slot:cell(original)="row">
          <template>
            <b-link :to="`/transactions/view/transaction/${row.item.id}`" :title="'ID: '+row.item.id">{{ $t(pathTranslation + 'original_view') }}</b-link>
          </template>
        </template>

        <!-- duplicate_count -->
        <template v-slot:cell(duplicate_count)="row">
          {{ row.item.potential_duplicate_transaction_ids.length }}
        </template>

        <!-- duplicate -->
        <template v-slot:cell(duplicates)="row">
          <template v-if="row.item.potential_duplicate_transaction_ids.length > 0">
            <template v-for="(duplicateItem, duplicateIndex) in row.item.potential_duplicate_transaction_ids">
            <b-link :to="`/transactions/view/transaction/${duplicateItem}`" :key="`duplicate_${duplicateItem}`" :title="'ID: '+duplicateItem">{{ duplicateIndex > 0 ? ' ,' : null}}{{ letter(duplicateIndex) }}</b-link>
          </template>
          </template>
        </template>

        <!-- head: checkbox -->
        <template v-slot:head(checkbox)>
          <b-form-checkbox v-model="allSelected" :indeterminate="indeterminate" @change="toggleAll"></b-form-checkbox>
        </template>

        <!--checkbox--->
        <template v-slot:cell(checkbox)="row">
          <b-form-checkbox-group v-model="multipleSelectedRows">
            <b-form-checkbox :value="row.item.potential_duplicate_transaction_ids.join(',')" class='text-left text-md-right mr-0'></b-form-checkbox>
          </b-form-checkbox-group>
        </template>
      </b-table>
    </template>

    <!-- DELETE MODAL -->
    <b-modal id="deleteOverview" centered ref="deleteOverview" modal-class="spirecta-modal">
      <template slot="modal-title">
        {{$t('common.action_please_confirm')}}
      </template>
      <h6 class="mb-0 font-weight-lighter">{{ $t('transactions.view.delete_description', { count: multipleSelectedRows.length }) }}</h6>
      <template slot="modal-footer" class="text-center">
        <b-button variant="outline" class="float-right" @click="$refs.deleteOverview.hide()">{{$t('common.no_cancel')}}</b-button>
        <b-button variant="danger" class="float-left" @click="deleteSelectedDuplticate">{{$t('common.yes_delete')}}</b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import _ from 'lodash'
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'

export default {
  name: 'TransactionsViewPredefinedSelectionsPotentialDuplicates',
  components: {
    Loader
  },
  props: ['startDate', 'endDate'],
  filters: {
    formatAmount
  },
  data () {
    return {
      duplicatesItems: [],
      pathTranslation: 'transactions.view-v2.predefined_selections.potential_duplicates.',
      busyState: false,
      allSelected: false,
      multipleSelectedRows: [],
      indeterminate: false,
      loadedStartDate: null,
      loadedEndDate: null
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    fieldValues () {
      return [
        { key: 'date', label: this.$t('common.date'), sortable: true, class: 'td-date text-left text-md-left dateRow' },
        { key: 'title', label: this.$t('common.title'), sortable: false, class: 'text-left font-weight-bolder' },
        { key: 'amount', label: this.$t('common.amount'), sortable: false, class: 'td-amount text-left text-md-right amountRow' },
        { key: 'original', label: this.$t('common.original'), sortable: false, class: ' td-amount text-left text-md-right amountRow' },
        { key: 'duplicate_count', label: this.$t(this.pathTranslation + 'duplicates_number'), sortable: false, class: 'td-amount text-left text-md-right amountRow' },
        { key: 'duplicates', label: this.$t(this.pathTranslation + 'duplicates'), sortable: false, class: 'td-amount text-left text-md-right amountRow' },
        { key: 'checkbox', label: '', sortable: false, class: 'text-left text-md-right' }
      ]
    }
  },
  methods: {
    /**
     * Shorthand for formatAmount
     */
    formatAmt (fAmount) {
      if (this.currentCOA.locale) {
        return formatAmount(fAmount, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 2)
      }
      return fAmount
    },
    letter (index) {
      const alphabets = 'abcdefghijklmnopqrstuvwxyz'
      return index <= 25 ? alphabets[index] : '.'
    },
    onRowSelected (item) {
      this.multipleSelectedRows = item
    },
    deleteSelectedDuplticate () {
      const selectedIds = _.flattenDeep([...this.multipleSelectedRows].map((el) => el.split(',')))

      axios.delete(`${process.env.VUE_APP_ROOT_API}/transactions/duplicates/entries`, { data: { transaction_ids: selectedIds } })
        .then(data => {
          this.multipleSelectedRows = []
          selectedIds.map(duplicateId => {
            this.duplicatesItems.map(transaction => {
              transaction.potential_duplicate_transaction_ids = transaction.potential_duplicate_transaction_ids.filter(id => id !== duplicateId)
            })
          })
          this.duplicatesItems = this.duplicatesItems.filter(transaction => transaction.potential_duplicate_transaction_ids.length)

          // ToDo: Delete commented out code if code above works well
          /* const self = this
          self.busyState = true
          self.fetchDuplicateTransaction(self.startDate, self.endDate)
            .then((response) => {
              self.duplicatesItems = response
            })
            .finally(() => {
              self.busyState = false
            }) */
          this.$bvToast.toast(this.$t('transactions.view.toast.delete_multiple_success_message').toString(), {
            title: this.$t('transactions.view.toast.title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
        }).catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t('transactions.view.toast.delete_multiple_failed_message').toString(), {
            title: this.$t('transactions.view.toast.title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
      this.$refs.deleteOverview.hide()
    },
    toggleAll (checked) {
      const transactionsItems = this.duplicatesItems
      if (checked) {
        transactionsItems.forEach(elem => {
          const selectedId = elem.potential_duplicate_transaction_ids.join(',')
          if (this.multipleSelectedRows.indexOf(selectedId) === -1) {
            this.multipleSelectedRows = [...this.multipleSelectedRows, selectedId]
          }
        })
      } else {
        this.multipleSelectedRows = []
      }
    },
    applyFilter () {
      if (this.startDate !== this.loadedStartDate || this.endDate !== this.loadedEndDate) {
        this.loadedStartDate = this.startDate
        this.loadedEndDate = this.endDate

        const self = this
        self.busyState = true
        self.fetchDuplicateTransaction(self.startDate, self.endDate)
          .then((response) => {
            const data = Object.values(response)
            self.duplicatesItems = data
          })
          .finally(() => {
            self.busyState = false
          })
      }
    },
    createToLink (sTitle) {
      this.$router.push({
        params: { tab: 'transactions' },
        query: { start_date: this.startDate, end_date: this.endDate, title: sTitle }
      })
    },
    fetchDuplicateTransaction (startDate, endDate) {
      /* if (isNaN(new Date(startDate)) || isNaN(new Date(endDate))) {
        return []
      } */

      let sParams = ''
      if (startDate !== null && !isNaN(new Date(startDate))) { sParams += '&start_date=' + startDate }
      if (endDate !== null && !isNaN(new Date(endDate))) { sParams += '&end_date=' + endDate }

      return axios.get(`${process.env.VUE_APP_ROOT_API}/transactions/duplicates?${sParams}`)
        .then(response => response.data.data)
        .then(responseData => {
          return responseData
        })
        .catch(err => {
          console.error(err)
          return []
        })
    }
  },
  watch: {
    multipleSelectedRows (newVal) {
      if (newVal.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newVal.length === this.$refs.DuplicateOverview.items.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
    startDate: {
      handler () {
        this.applyFilter()
      },
      immediate: true,
      deep: true
    },
    endDate: {
      handler () {
        this.applyFilter()
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
.component-transaction-view-predefined-selection-potential-duplicates {
  tbody {
    td {
      &:nth-child(1) {
        @media screen and (min-width: 992px){
          width: 130px;
        }
      }
    }
  }
}
.main-gradient-content-card {
  position: relative;
  top: 0;
}
</style>
