<template>
  <div class="component-transaction-view-predefined-selection">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters v-show="isLoaded">
                <b-col cols="8" v-if="startDate !== null">
                  <h2>{{ $t(translationPath + 'title',{start_date: startDate, end_date: endDate }) }}</h2>
                </b-col>
              </b-row>

              <b-card no-body class="white-tabs-card px-0 mx-0 mt-4">
                <b-tabs class="white-tabs filter-tabs-wrapper" v-model="tabIndex">

                  <!-- Potential duplicates -->
                  <b-tab :title="$t(translationPath + 'potential_duplicates.tab_title')" class="px-0">
                    <div class="col-lg-8 pl-0 pt-3" v-html="$t(translationPath + 'potential_duplicates.duplicate_description', {period_from: startDate, period_end: endDate})"></div>
                    <transaction-view-predefined-selection-potential-duplicates-table v-if="tabIndex===0" :startDate="startDate" :endDate="endDate"></transaction-view-predefined-selection-potential-duplicates-table>
                  </b-tab>

                  <!-- Potential duplicate transfers -->
                  <b-tab :title="$t(translationPath + 'duplicate_transfers.tab_title')" class="px-0">
                    <transactions-view-predefined-selections-potential-duplicate-transfers-table class="pt-3" v-if="tabIndex===1" :startDate="startDate" :endDate="endDate"></transactions-view-predefined-selections-potential-duplicate-transfers-table>
                  </b-tab>

                  <!-- Imports -->
                  <b-tab :title="$t(translationPath + 'imports.tab_title')" class="px-0">
                    <div class="col-lg-8 pl-0 pt-3" v-html="$t(translationPath + 'imports.description', {period_from: startDate, period_end: endDate})"></div>
                    <transactions-view-predefined-selections-imports-table v-if="tabIndex===2" :startDate="startDate" :endDate="endDate"></transactions-view-predefined-selections-imports-table>
                  </b-tab>

                  <!-- Accounts with transactions in period -->
                  <b-tab :title="$t(translationPath + 'transactions_per_accounts.tab_title')" class="px-0">
                    <transactions-view-predefined-selections-transactions-per-accounts-table class="pt-3" v-if="tabIndex===3" :startDate="startDate" :endDate="endDate"></transactions-view-predefined-selections-transactions-per-accounts-table>
                  </b-tab>

                  <!-- All transfers -->
                  <b-tab :title="$t(translationPath + 'all_transfers.tab_title')" class="px-0">
                    <transactions-view-predefined-selections-all-transfers-table class="pt-3" v-if="tabIndex===4" :startDate="startDate" :endDate="endDate"></transactions-view-predefined-selections-all-transfers-table>
                  </b-tab>

                </b-tabs>
              </b-card>

            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import TransactionsViewMixin from './../inc/TransactionsViewMixin'
import TransactionViewPredefinedSelectionPotentialDuplicatesTable from '../tables/PredefinedSelections/PotentialDuplicatesTable'
import TransactionsViewPredefinedSelectionsImportsTable from '../tables/PredefinedSelections/ImportsTable'
import TransactionsViewPredefinedSelectionsPotentialDuplicateTransfersTable from '../tables/PredefinedSelections/PotentialDuplicateTransfersTable'
import TransactionsViewPredefinedSelectionsTransactionsPerAccountsTable from '../tables/PredefinedSelections/TransactionsPerAccountsTable'
import TransactionsViewPredefinedSelectionsAllTransfersTable from '../tables/PredefinedSelections/AllTransfers'

import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'

export default {
  name: 'TransactionViewPredefinedSelections',
  mixins: [TransactionsViewMixin],
  components: {
    TransactionViewPredefinedSelectionPotentialDuplicatesTable,
    TransactionsViewPredefinedSelectionsPotentialDuplicateTransfersTable,
    TransactionsViewPredefinedSelectionsImportsTable,
    TransactionsViewPredefinedSelectionsTransactionsPerAccountsTable,
    TransactionsViewPredefinedSelectionsAllTransfersTable
  },
  filters: {
    formatAmount
  },
  props: {
    isLoaded: { type: Boolean, default: false },
    activeTab: { type: String, default: '' },
    aFilterParams: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      translationPath: 'transactions.view-v2.predefined_selections.',
      tabIndex: 0,
      startDate: null,
      endDate: null
    }
  },
  created () {
    this.startDate = this.aFilterParams.start_date
    this.endDate = this.aFilterParams.end_date
    if (this.aFilterParams.startDate === null) {
      this.startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).toISOString().split('T')[0]
    }
    if (this.aFilterParams.end_date === null) {
      this.endDate = new Date().toISOString().split('T')[0]
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA'])
  },
  methods: {
    onRouteChange (tab) {
      const { params, query } = this.$route
      switch (tab) {
        case 'potential_duplicates':
          this.tabIndex = 0
          break
        case 'duplicate_transfers':
          this.tabIndex = 1
          break
        case 'imports':
          this.tabIndex = 2
          break
        case 'transactions_per_accounts':
          this.tabIndex = 3
          break
        case 'all_transfers':
          this.tabIndex = 4
          break
        default:
          this.$router.push({ params: Object.assign({}, params, { subtab: 'potential_duplicates' }), query })
      }
    }
  },
  watch: {
    aFilterParams: {
      handler: function () {
        this.startDate = this.aFilterParams.start_date
        this.endDate = this.aFilterParams.end_date
      },
      deep: true,
      immediate: true
    },
    tabIndex (newVal) {
      const { params, query } = this.$route
      switch (newVal) {
        case 0:
          if (this.$route.params.subtab !== 'potential_duplicates') {
            this.$router.push({ params: Object.assign({}, params, { subtab: 'potential_duplicates' }), query })
          }
          break
        case 1:
          if (this.$route.params.subtab !== 'duplicate_transfers') {
            this.$router.push({ params: Object.assign({}, params, { subtab: 'duplicate_transfers' }), query })
          }
          break
        case 2:
          if (this.$route.params.subtab !== 'imports') {
            this.$router.push({ params: Object.assign({}, params, { subtab: 'imports' }), query })
          }
          break
        case 3:
          if (this.$route.params.subtab !== 'transactions_per_accounts') {
            this.$router.push({ params: Object.assign({}, params, { subtab: 'transactions_per_accounts' }), query })
          }
          break
        case 4:
          if (this.$route.params.subtab !== 'all_transfers') {
            this.$router.push({ params: Object.assign({}, params, { subtab: 'all_transfers' }), query })
          }
          break
      }
    },
    '$route.params.subtab': {
      handler: function (newVal) {
        this.onRouteChange(newVal)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
.component-transaction-view-predefined-selection{
  .filter-tabs-wrapper ul li a{
    color:#d38786;
  }
  .white-tabs-card {
    overflow-x: auto;
  }
}
</style>
