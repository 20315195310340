<template>
  <div class="component-transaction-view-stats-tables">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters v-show="isLoaded">
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'title', {period_from: aFilterParams.start_date, period_to:aFilterParams.end_date }) }}</h2>
                </b-col>
              </b-row>

              <b-card no-body class="white-tabs-card px-0 mx-0 mt-4">
                <b-tabs class="white-tabs filter-tabs-wrapper" v-model="tabIndex">

                  <!-- Summary -->
                  <b-tab :title="$t(translationPath + 'tabs.summary_stats')" class="px-3">
                    <div class="col-lg-8 pl-0 pt-3" v-html="$t(translationPath + 'description.summary_stats')"></div>
                    <b-table class="spirecta-simple-table summary-table mb-0" show-empty hover responsive striped stacked="sm"
                             :items="aTableData.summary_stats"
                             :fields="aTableColumns.summary_stats"
                             :busy="!isLoaded"
                             :filter="sTableFilter"
                    >
                    </b-table>
                  </b-tab>

                  <!-- LARGEST INCOMES -->
                  <b-tab :title="$t(translationPath + 'tabs.largest_incomes')" class="px-3">
                    <div class="col-lg-8 pl-0 pt-3" v-html="$t(translationPath + 'description.largest_incomes')"></div>
                    <b-table class="spirecta-simple-table mb-0" show-empty hover responsive striped stacked="sm"
                             :items="aTableData.largestIncomes"
                             :fields="aTableColumns.largestIncomes"
                             :busy="!isLoaded"
                             :filter="sTableFilter"
                             :filter-included-fields="['title']"
                    >
                      <!-- FILTER -->
                      <template slot="top-row">
                        <td :colspan="(aTableColumns.largestIncomes.length)">
                          <div class="d-flex align-items-center">
                            <i class="fa fa-search text-secondary"></i>
                            <b-form-input v-model="sTableFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                          </div>
                        </td>
                      </template>

                      <!-- title slot-->
                      <template v-slot:cell(title)="row">
                        <b-link :to="'/transactions/view/transaction/' + row.item.id">{{row.value}}</b-link>
                      </template>
                    </b-table>
                  </b-tab>

                  <!-- LARGEST EXPENSES -->
                  <b-tab :title="$t(translationPath + 'tabs.largest_expenses')" class="px-3">
                    <div class="col-lg-8 pl-0 pt-3" v-html="$t(translationPath + 'description.largest_expenses')"></div>
                    <b-table class="spirecta-simple-table mb-0" show-empty hover responsive striped stacked="sm"
                             :items="aTableData.largestExpenses"
                             :fields="aTableColumns.largestExpenses"
                             :busy="!isLoaded"
                             :filter="sTableFilter"
                             :filter-included-fields="['title']"
                    >
                      <!-- FILTER -->
                      <template slot="top-row">
                        <td :colspan="(aTableColumns.largestExpenses.length)">
                          <div class="d-flex align-items-center">
                            <i class="fa fa-search text-secondary"></i>
                            <b-form-input v-model="sTableFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                          </div>
                        </td>
                      </template>

                      <!-- title slot-->
                      <template v-slot:cell(title)="row">
                          <b-link :to="'/transactions/view/transaction/' + row.item.id">{{row.value}}</b-link>
                      </template>
                    </b-table>
                  </b-tab>

                  <!-- FREQUENCY -->
                  <b-tab :title="$t(translationPath + 'tabs.frequency')" class="px-3">
                    <div class="col-lg-8 pl-0 pt-3" v-html="$t(translationPath + 'description.frequency')"></div>
                    <b-table class="spirecta-simple-table mb-0" show-empty hover responsive striped stacked="sm"
                             :items="aTableData.frequency"
                             :fields="aTableColumns.frequency"
                             :busy="!isLoaded"
                             :filter="sTableFilter"
                             :filter-included-fields="['title']"
                    >
                      <!-- FILTER -->
                      <template slot="top-row">
                        <td :colspan="(aTableColumns.frequency.length)">
                          <div class="d-flex align-items-center">
                            <i class="fa fa-search text-secondary"></i>
                            <b-form-input v-model="sTableFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                          </div>
                        </td>
                      </template>

                      <!-- title slot-->
                      <template v-slot:cell(title)="row">
                        <b-link @click="openTransactionTitle(row.value)">{{row.value}}</b-link>
                      </template>
                    </b-table>
                  </b-tab>
                </b-tabs>
              </b-card>

            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import TansactionsViewMixin from '../inc/TransactionsViewMixin'
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'

export default {
  name: 'TransactionViewStatsTables',
  mixins: [TansactionsViewMixin],
  components: {
  },
  filters: {
    formatAmount
  },
  props: {
    isLoaded: { type: Boolean, default: false },
    activeTab: { type: String, default: '' },
    aFilterParams: {
      type: Object,
      default: function () {
        return {
        }
      }
    },
    apiData: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  data () {
    return {
      translationPath: 'transactions.view-v2.stats.',
      sTableFilter: '',
      aColumnsToFilterOn: ['title'],
      tabIndex: 0,
      aTableData: { largestIncomes: [], largestExpenses: [], frequency: [], summary_stats: [] },
      aTableColumns: { largestIncomes: [], largestExpenses: [], frequency: [], summary_stats: [] }
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA'])
  },
  methods: {
    /**
     * Shorthand for formatAmount
     */
    formatAmt (fAmount) {
      if (this.currentCOA.locale) {
        return formatAmount(fAmount, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 2)
      }
      return fAmount
    },
    openTransactionTitle (sTitle) {
      this.$router.push({
        params: { tab: 'transactions' },
        query: { start_date: this.aFilterParams.start_date, end_date: this.aFilterParams.end_date, title: sTitle }
      })
    },
    buildTableDataRows () {
      if (!this.isLoaded) {
        return []
      }

      // Prep
      this.aTableData.largestIncomes = []
      this.aTableData.largestExpenses = []
      this.aTableData.frequency = []
      this.aTableData.summary_stats = []
      let oTransaction = null
      let oData = null

      // Load largest incomes
      for (const idx in this.apiData.stats.largest_incomes) {
        oTransaction = this.apiData.stats.largest_incomes[idx]

        this.aTableData.largestIncomes.push({
          id: oTransaction.id,
          date: oTransaction.date,
          title: oTransaction.title,
          account_title: oTransaction.cr_entries[0].account_title,
          amount: Math.abs(oTransaction.amount)
        })
      }

      // Load largest expenses
      for (const idx in this.apiData.stats.largest_expenses) {
        oTransaction = this.apiData.stats.largest_expenses[idx]

        this.aTableData.largestExpenses.push({
          id: oTransaction.id,
          date: oTransaction.date,
          title: oTransaction.title,
          account_title: oTransaction.dr_entries[0].account_title,
          amount: Math.abs(oTransaction.amount)
        })
      }

      // Load frequency
      for (const idx in this.apiData.stats.frequency) {
        oData = this.apiData.stats.frequency[idx]

        if (oData.stats.count >= 1) {
          this.aTableData.frequency.push({
            title: oData.title,
            sum: Math.abs(oData.stats.sum),
            count: Math.abs(oData.stats.count),
            average: Math.abs(oData.stats.average),
            median: Math.abs(oData.stats.median),
            min: Math.abs(oData.stats.min),
            max: Math.abs(oData.stats.max)
          })
        }
      }

      // Build summary stats
      this.aTableData.summary_stats.push({ title: this.$t('common.incomes'), count: this.apiData.stats.incomes.cnt_transactions, sum: this.apiData.stats.incomes.sum, average: this.apiData.stats.incomes.average, median: this.apiData.stats.incomes.median })
      this.aTableData.summary_stats.push({ title: this.$t('common.expenses'), count: this.apiData.stats.expenses.cnt_transactions, sum: this.apiData.stats.expenses.sum, average: this.apiData.stats.expenses.average, median: this.apiData.stats.expenses.median })
      this.aTableData.summary_stats.push({ title: this.$t('common.other'), count: this.apiData.stats.other.cnt_transactions, sum: this.apiData.stats.other.sum, average: this.apiData.stats.other.average, median: this.apiData.stats.other.median })
      this.aTableData.summary_stats.push({ title: this.$t('common.sum'), count: this.apiData.stats.all.cnt_transactions, sum: this.apiData.stats.all.sum, average: this.apiData.stats.all.average, median: this.apiData.stats.all.median })

      this.buildTableColumns()
    },
    buildTableColumns () {
      let aFields = [
        { key: 'title', label: this.$t(this.translationPath + 'columns.title'), sortable: true, class: 'text-left td-title' },
        { key: 'amount', label: this.$t(this.translationPath + 'columns.amount'), sortable: true, class: 'text-right td-amount', formatter: this.formatAmt },
        { key: 'account_title', label: this.$t(this.translationPath + 'columns.account_title'), sortable: true, class: 'text-left td-account_title' },
        { key: 'date', label: this.$t(this.translationPath + 'columns.date'), sortable: true, class: 'text-left td-date' }
      ]
      this.aTableColumns.largestIncomes = aFields
      this.aTableColumns.largestExpenses = aFields

      aFields = [
        { key: 'title', label: this.$t(this.translationPath + 'columns.title'), sortable: true, class: 'text-left td-title' },
        { key: 'count', label: this.$t(this.translationPath + 'columns.count'), sortable: true, class: 'text-right td-count' },
        { key: 'sum', label: this.$t(this.translationPath + 'columns.sum'), sortable: true, class: 'text-right td-sum', formatter: this.formatAmt },
        { key: 'average', label: this.$t(this.translationPath + 'columns.average'), sortable: true, class: 'text-right td-average', formatter: this.formatAmt },
        { key: 'median', label: this.$t(this.translationPath + 'columns.median'), sortable: true, class: 'text-right td-median', formatter: this.formatAmt },
        { key: 'max', label: this.$t(this.translationPath + 'columns.max'), sortable: true, class: 'text-right td-max', formatter: this.formatAmt },
        { key: 'min', label: this.$t(this.translationPath + 'columns.min'), sortable: true, class: 'text-right td-min', formatter: this.formatAmt }
      ]
      this.aTableColumns.frequency = aFields

      aFields = [
        { key: 'title', label: this.$t(this.translationPath + 'columns.title'), sortable: true, class: 'text-left td-title' },
        { key: 'count', label: this.$t(this.translationPath + 'columns.count'), sortable: true, class: 'text-right td-count', formatter: (val) => (val + ' ' + this.$t('common.pcs')) },
        { key: 'sum', label: this.$t(this.translationPath + 'columns.sum'), sortable: true, class: 'text-right td-sum', formatter: this.formatAmt },
        { key: 'average', label: this.$t(this.translationPath + 'columns.average'), sortable: true, class: 'text-right td-average', formatter: this.formatAmt },
        { key: 'median', label: this.$t(this.translationPath + 'columns.median'), sortable: true, class: 'text-right td-median', formatter: this.formatAmt }
      ]
      this.aTableColumns.summary_stats = aFields
    },
    onRouteChange (tab) {
      const { params, query } = this.$route
      switch (tab) {
        case 'summary_stats':
          this.tabIndex = 0
          break
        case 'largest_incomes':
          this.tabIndex = 1
          break
        case 'largest_expenses':
          this.tabIndex = 2
          break
        case 'frequency':
          this.tabIndex = 3
          break
        default:
          this.$router.push({ params: Object.assign({}, params, { subtab: 'frequency' }), query })
      }
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: 'buildTableDataRows',
      deep: true,
      immediate: true
    },
    activeTab: {
      handler: 'buildTableDataRows',
      deep: true,
      immediate: true
    },
    tabIndex (newVal) {
      const { params, query } = this.$route
      switch (newVal) {
        case 0:
          if (this.$route.params.subtab !== 'summary_stats') {
            this.$router.push({ params: Object.assign({}, params, { subtab: 'summary_stats' }), query })
          }
          break
        case 1:
          if (this.$route.params.subtab !== 'largest_incomes') {
            this.$router.push({ params: Object.assign({}, params, { subtab: 'largest_incomes' }), query })
          }
          break
        case 2:
          if (this.$route.params.subtab !== 'largest_expenses') {
            this.$router.push({ params: Object.assign({}, params, { subtab: 'largest_expenses' }), query })
          }
          break
        case 3:
          if (this.$route.params.subtab !== 'frequency') {
            this.$router.push({ params: Object.assign({}, params, { subtab: 'frequency' }), query })
          }
          break
      }
    },
    '$route.params.subtab': {
      handler: function (newVal) {
        this.onRouteChange(newVal)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
.component-transaction-view-stats-tables{
  .filter-tabs-wrapper ul li a{
    color:#d38786;
  }

  .summary-table{
    tr:nth-child(4) td{
      border-top:1px solid black;
      border-bottom:1px solid black;
      font-weight:700;
    }
  }
}
</style>
