<template>
  <div class="component-transaction-view-predefined-selections-transactions-per-accounts-table">

    <b-row no-gutters>
      <b-col class="col-md-8">
        <p>{{ $t(translationPath + 'description',{ period_from: startDate, period_to: endDate}) }}</p>
      </b-col>
    </b-row>

    <b-table-simple hover striped v-if="Object.keys(accountsWithTransactionsData).length !== 0 && busyState === false">
      <b-thead>
        <b-tr>
          <b-th>{{ $t(translationPath + 'is_importable') }}</b-th>
          <template v-for="(item,year_month) in accountsWithTransactionsData.year_months">
            <b-th class="text-center" :key="year_month">{{year_month}}</b-th>
          </template>
        </b-tr>
      </b-thead>

      <!-- BODY -->
      <b-tbody>
        <!-- IS_IMPORTABLE ACCOUNTS -->
        <template v-for="(account,account_id) in accountsWithTransactionsData.is_importable">
          <b-tr :key="account_id">
            <b-td>
              <b-link class="text-black" :to="'/reports/performance/accounts/'+account.id+'/view'">{{account.label}}</b-link>
            </b-td>
            <template v-for="(cnt_transactions,year_month) in account.year_month">
              <b-td :class="'text-center ' + (cnt_transactions === 0 ? 'bg_not_ok' : 'bg_ok')" :key="year_month">
                <b-link class="text-black" @click="openToLink(account.id, year_month)">{{cnt_transactions}}</b-link>
              </b-td>
            </template>
          </b-tr>
        </template>
        <b-tr>
          <b-td class="bg_white">&nbsp;</b-td>
          <template v-for="(item,year_month) in accountsWithTransactionsData.year_months">
            <b-td class="bg_white" :key="year_month">&nbsp;</b-td>
          </template>
        </b-tr>
        <b-tr>
          <b-th class="bg_white">{{ $t(translationPath + 'other_assets') }}</b-th>
          <template v-for="(item,year_month) in accountsWithTransactionsData.year_months">
            <b-th class="text-center bg_white" :key="year_month">{{year_month}}</b-th>
          </template>
        </b-tr>
        <!-- ASSETS -->
        <template v-for="(account,account_id) in accountsWithTransactionsData.asset">
          <b-tr :key="'asset_'+account_id">
            <b-td><b-link class="text-black" :to="'/reports/performance/accounts/'+account.id+'/view'">{{account.label}}</b-link></b-td>
            <template v-for="(cnt_transactions,year_month) in account.year_month">
              <b-td :class="'text-center ' + (cnt_transactions === 0 ? 'bg_not_ok' : 'bg_ok')" :key="year_month">
                <b-link class="text-black" @click="openToLink(account.id, year_month)">{{cnt_transactions}}</b-link>
              </b-td>
            </template>
          </b-tr>
        </template>
        <b-tr>
          <b-td class="bg_white">&nbsp;</b-td>
          <template v-for="(item,year_month) in accountsWithTransactionsData.year_months">
            <b-td class="bg_white" :key="year_month">&nbsp;</b-td>
          </template>
        </b-tr>
        <b-tr>
          <b-th class="bg_white">{{ $t(translationPath + 'other_liabilities') }}</b-th>
          <template v-for="(item,year_month) in accountsWithTransactionsData.year_months">
            <b-th class="text-center bg_white" :key="year_month">{{year_month}}</b-th>
          </template>
        </b-tr>
        <!-- LIABILITIES -->
        <template v-for="(account,account_id) in accountsWithTransactionsData.liability">
          <b-tr :key="'liability_'+account_id">
            <b-td><b-link class="text-black" :to="'/reports/performance/accounts/'+account.id+'/view'">{{account.label}}</b-link></b-td>
            <template v-for="(cnt_transactions,year_month) in account.year_month">
              <b-td :class="'text-center ' + (cnt_transactions === 0 ? 'bg_not_ok' : 'bg_ok')" :key="year_month">
                <b-link class="text-black" @click="openToLink(account.id, year_month)">{{cnt_transactions}}</b-link>
              </b-td>
            </template>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>

  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'

export default {
  name: 'TransactionsViewPredefinedSelectionsTransactionsPerAccountsTable',
  components: {
    // Loader
  },
  props: ['startDate', 'endDate'],
  data () {
    return {
      accountsWithTransactionsData: [],
      translationPath: 'transactions.view-v2.predefined_selections.transactions_per_accounts.',
      busyState: false,
      allSelected: false,
      loadedStartDate: null,
      loadedEndDate: null,
      multipleSelectedRows: [],
      indeterminate: false
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA'])
  },
  methods: {
    applyFilter () {
      const self = this

      if (this.startDate !== this.loadedStartDate || this.endDate !== this.loadedEndDate) {
        self.busyState = true
        this.loadedStartDate = this.startDate
        this.loadedEndDate = this.endDate
        self.fetchAccountsWithTransactions(self.startDate, self.endDate)
          .then((response) => {
            self.accountsWithTransactionsData = response
          })
          .finally(() => {
            self.busyState = false
          })
      }
    },
    openToLink (iSourceAccountId, sYearMonth) {
      this.$router.push({
        params: { tab: 'transactions' },
        query: { start_date: sYearMonth + '-01', end_date: this.getLastDayInMonth(sYearMonth), source_account_id: iSourceAccountId }
      })
    },
    getLastDayInMonth (sYearMonth) {
      const sReturnDate = new Date((new Date(sYearMonth.substring(0, 4), sYearMonth.substring(5, 7), 1)) - 1)

      return sReturnDate.toISOString().split('T')[0]
    },
    fetchAccountsWithTransactions (startDate, endDate) {
      let sParams = ''
      if (startDate !== null && !isNaN(new Date(startDate))) { sParams += '?start_date=' + startDate }
      if (endDate !== null && !isNaN(new Date(endDate))) {
        sParams += (sParams ? '&' : '?') + 'end_date=' + endDate
      }

      return axios.get(`${process.env.VUE_APP_ROOT_API}/transactions/accounts/with/transactions${sParams}`)
        .then(response => response.data.data)
        .then(responseData => {
          return responseData
        })
        .catch(err => {
          console.error(err)
          return []
        })
    }
  },
  filters: {
    formatAmount
  },
  watch: {
    startDate: {
      handler () {
        this.applyFilter(this.startDate, this.endDate)
      },
      immediate: true,
      deep: true
    },
    endDate: {
      handler () {
        this.applyFilter(this.startDate, this.endDate)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
.component-transaction-view-predefined-selections-transactions-per-accounts-table {
  .bg_not_ok{background: rgba(255,202,40,0.3)}
  .bg_ok{background: rgba(140,204,175,0.5)}
  .bg_white{background: #FFFFFF}
  th{border-top:0;}
}
</style>
