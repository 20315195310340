<template>
  <div class="quick-filter">
    <!-- QUICK FILTER DROPDOWNS -->
    <b-row class="px-3 pb-3" v-if="bIsDropdownsShown">
      <b-col>
        <!-- Filter: category -->
        <b-dropdown left :text="$t( translationPath + 'filters.category',{value:translateAccountId(aFilters.account_id)})" variant='outline-secondary' class="text-regular mr-3 mb-1 toggleColumnWrapper" size="sm">
          <div style="width:400px;padding:20px;">
            <b-form-group label-for="dropdown-form-email" @submit.stop.prevent>
              <custom-select
                :options="aAccountDataIncomeExpenseCustomSelect"
                :displayGroupWithTitle="true"
                groupingValues="accounts"
                groupingLabel="label"
                groupingValuesSecondary="groupAccounts"
                groupingLabelSecondary="label"
                :placeholder="$t('common.select_category_default_options').toString()"
                :search-placeholder="$t('common.type_to_filter').toString()"
                :noResultText="$t('common.no_option_found').toString()"
                @open="loadCustomSelectIncomeExpenseAccounts"
                :sortGroupLabel="false"
                label="text"
                v-model="aFilters.account_id"
              >
              </custom-select>
              <b-button size="sm" class="btn btn-sm float-left mt-2" variant="outline-secondary" @click="clear('account_id')">{{$t(translationPath+'labels.btn_filter_reset')}}</b-button>
              <b-button size="sm" class="btn btn-sm float-right mt-2" variant="primary" @click="update('account_id')">{{$t(translationPath+'labels.btn_filter')}}</b-button>
            </b-form-group>
          </div>
        </b-dropdown>

        <!-- Filter: title -->
        <b-dropdown left :text="$t( translationPath + 'filters.title',{value: aFilters.title})" variant='outline-secondary' class="text-regular mr-3 mb-1 toggleColumnWrapper" size="sm">
          <div style="width:400px;padding:20px;">
            <b-form-group :label="$t(translationPath + 'labels.title')"  label-for="quick_filter-transaction_title">
              <b-form-input id="quick_filter-transaction_title" size="sm" :placeholder="$t(translationPath + 'labels.title_placeholder')" v-model="aFilters.title"></b-form-input>
              <b-button size="sm" class="btn btn-sm float-left mt-2" variant="outline-secondary" @click="clear('title')">{{$t(translationPath+'labels.btn_filter_reset')}}</b-button>
              <b-button size="sm" class="btn btn-sm float-right mt-2" variant="primary" @click="update('title')">{{$t(translationPath+'labels.btn_filter')}}</b-button>
            </b-form-group>
          </div>
        </b-dropdown>

        <!-- Filter: transaction_subtype -->
        <b-dropdown left :text="$t( translationPath + 'labels.transaction_subtype',{value: translateTransactionType(aFilters.transaction_subtype)})" variant='outline-secondary' class="text-regular mr-3 mb-1 toggleColumnWrapper" size="sm">
          <div style="width:400px;padding:10px 20px;">
            <b-form-group :label="$t(translationPath + 'labels.transaction_subtype',{value:''})"  label-for="quick_filter-transaction_subtype">
              <b-form-select v-model="aFilters.transaction_subtype" :options="aTransactionSubtypes" size="sm"></b-form-select>
              <b-button size="sm" class="btn btn-sm float-left mt-2" variant="outline-secondary" @click="clear('transaction_subtype')">{{$t(translationPath+'labels.btn_filter_reset')}}</b-button>
              <b-button size="sm" class="btn btn-sm float-right mt-2" variant="primary" @click="update('transaction_subtype')">{{$t(translationPath+'labels.btn_filter')}}</b-button>
            </b-form-group>
          </div>
        </b-dropdown>

        <!-- Filter: amount -->
        <b-dropdown left :text="$t( translationPath + 'filters.amount_' + getAmountLabelBasedOnAmounts(), {amount_min: aFilters.amount_min, amount_max: aFilters.amount_max})" variant='outline-secondary' class="text-regular mr-3 mb-1 toggleColumnWrapper" size="sm">
          <div style="width:400px;padding:20px;">
            <b-row>
              <b-col cols="6">
                <b-form-group :label="$t(translationPath + 'labels.min_amount',{value:''})" label-for="quick_filter-min_amount" class="pb-0 mb-0">
                  <b-form-input id="quick_filter-min_amount" v-model="aFilters.amount_min" size="sm"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="$t(translationPath + 'labels.max_amount',{value:''})" label-for="quick_filter-max_amount" class="pb-0 mb-0">
                  <b-form-input id="quick_filter-max_amount" v-model="aFilters.amount_max" size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <p style="line-height: 15px;padding-top:3px"><small>{{$t(translationPath + 'labels.amount_description')}}</small></p>
            <b-button size="sm" class="btn btn-sm float-left mt-2" variant="outline-secondary" @click="clear('amount')">{{$t(translationPath+'labels.btn_filter_reset')}}</b-button>
            <b-button size="sm" class="btn btn-sm float-right mt-2" variant="primary" @click="update('amount')">{{$t(translationPath+'labels.btn_filter')}}</b-button>
          </div>
        </b-dropdown>

        <!-- Filter: description -->
        <b-dropdown left :text="$t(translationPath + 'filters.description',{value: aFilters.description})" variant='outline-secondary' class="text-regular mr-3 mb-1 toggleColumnWrapper" size="sm">
          <div style="width:400px;padding:20px;">
            <b-form-group :label="$t(translationPath + 'labels.description')"  label-for="quick_filter-transaction_description">
              <b-form-input id="quick_filter-transaction_description" size="sm" :placeholder="$t(translationPath + 'labels.description_placeholder')" v-model="aFilters.description"></b-form-input>
              <b-button size="sm" class="btn btn-sm float-left mt-2" variant="outline-secondary" @click="clear('description')">{{$t(translationPath+'labels.btn_filter_reset')}}</b-button>
              <b-button size="sm" class="btn btn-sm float-right mt-2" variant="primary" @click="update('description')">{{$t(translationPath+'labels.btn_filter')}}</b-button>
            </b-form-group>
          </div>
        </b-dropdown>

        <!-- Filter: source account -->
        <b-dropdown left :text="$t( translationPath + 'filters.source_account',{value:translateAccountId(aFilters.source_account_id)})" variant='outline-secondary' class="text-regular mr-3 mb-1 toggleColumnWrapper" size="sm">
          <div style="width:400px;padding:20px;">
            <b-form-group label-for="dropdown-form-email" @submit.stop.prevent>
              <custom-select
                             :options="aAccountDataAssetLiabilityCustomSelect"
                             :displayGroupWithTitle="true"
                             groupingValues="accounts"
                             groupingLabel="label"
                             groupingValuesSecondary="groupAccounts"
                             groupingLabelSecondary="label"
                             :placeholder="$t('common.select_category_default_options').toString()"
                             :search-placeholder="$t('common.type_to_filter').toString()"
                             :noResultText="$t('common.no_option_found').toString()"
                             @open="loadCustomSelectAssetLiabilityAccounts"
                             :sortGroupLabel="false"
                             label="text"
                             v-model="aFilters.source_account_id"
              >
              </custom-select>
              <b-button size="sm" class="btn btn-sm float-left mt-2" variant="outline-secondary" @click="clear('source_account_id')">{{$t(translationPath+'labels.btn_filter_reset')}}</b-button>
              <b-button size="sm" class="btn btn-sm float-right mt-2" variant="primary" @click="update('source_account_id')">{{$t(translationPath+'labels.btn_filter')}}</b-button>
            </b-form-group>
          </div>
        </b-dropdown>
        <!-- END filters -->
      </b-col>
    </b-row>
    <!-- END QUICK FILTER DROPDOWNS -->

    <!-- BADGE FILTERS -->
    <b-row class="px-3 pb-3 filterBadgeRow" v-if="!bIsDropdownsShown">
      <b-col>
        <b-badge variant="primary" class="mr-3 mb-1" v-if="aFilters.start_date !== null"><b-link @click="clear('start_date')" class="badge-close">x</b-link> {{$t( translationPath + 'filters.start_date',{value: aFilters.start_date})}}</b-badge>
        <b-badge variant="primary" class="mr-3 mb-1" v-if="aFilters.end_date !== null"><b-link @click="clear('end_date')" class="badge-close">x</b-link> {{$t( translationPath + 'filters.end_date',{value: aFilters.end_date})}}</b-badge>
        <b-badge variant="primary" class="mr-3 mb-1" v-if="aFilters.title !== null"><b-link @click="clear('title')" class="badge-close">x</b-link> {{$t( translationPath + 'filters.title',{value: aFilters.title})}}</b-badge>
        <b-badge variant="primary" class="mr-3 mb-1" v-if="aFilters.description !== null"><b-link @click="clear('description')" class="badge-close">x</b-link> {{$t( translationPath + 'filters.description',{value: aFilters.description})}}</b-badge>
        <b-badge variant="primary" class="mr-3 mb-1" v-if="aFilters.transaction_subtype !== null" ><b-link @click="clear('transaction_subtype')" class="badge-close">x</b-link> {{$t( translationPath + 'labels.transaction_subtype',{value: translateTransactionType(aFilters.transaction_subtype)})}}</b-badge>
        <b-badge variant="primary" class="mr-3 mb-1" v-if="aFilters.amount_min !== null || aFilters.amount_max !== null" ><b-link @click="clear('amount')" class="badge-close">x</b-link> {{$t( translationPath + 'filters.amount_' + getAmountLabelBasedOnAmounts(), {amount_min: aFilters.amount_min, amount_max: aFilters.amount_max})}}</b-badge>
        <b-badge variant="primary" class="mr-3 mb-1" v-if="aFilters.import_id !== null" ><b-link @click="clear('import_id')" class="badge-close">x</b-link> {{$t( translationPath + 'labels.import_id',{value: aFilters.import_id})}}</b-badge>
        <b-badge variant="primary" class="mr-3 mb-1" v-if="aFilters.account_id !== null" ><b-link @click="clear('account_id')" class="badge-close">x</b-link> {{$t( translationPath + 'labels.account_id',{value: translateAccountId(aFilters.account_id)})}}</b-badge>
        <b-badge variant="primary" class="mr-3 mb-1" v-if="aFilters.source_account_id !== null" ><b-link @click="clear('source_account_id')" class="badge-close">x</b-link> {{$t( translationPath + 'labels.source_account_id',{value: translateAccountId(aFilters.source_account_id)})}}</b-badge>
        <b-badge variant="primary" class="mr-3 mb-1" v-if="aFilters.account_group_id !== null" ><b-link @click="clear('account_group_id')" class="badge-close">x</b-link> {{$t( translationPath + 'labels.account_group_id',{value: translateAccountGroupId(aFilters.account_group_id)})}}</b-badge>
      </b-col>
    </b-row>
    <!-- END BADGE FILTERS -->
  </div>
</template>

<script>
/**
 * Quick Filter for transactions view table.
 *
 * Provides few dropdowns in which user can input title, amount, account, etc.
 * Initial state loads from filters property, so it should be defined in parent component.
 * Also filter can show filter badges. Toggle between badges and dropdowns can be controlled
 * by showDropdowns property.
 *
 * Todo: make each dropdown as separated component. It will be useful because there are few
 * dropdown types we are use: text filter, amount filter, select filter and custom select filter.
 * So if we will want to add one more text filter we would just add TextFilter component or so.
 */

import CustomSelect from '@/components/common/CustomSelect'

export default {
  name: 'QuickFilter',
  components: { CustomSelect },
  emits: ['update', 'clear'], // Update - on changing filter value, clear - on resetting filter
  props: {
    /** True for showing dropdowns, false for showning badges.
     */
    showDropdowns: {
      type: Boolean,
      default: false
    },

    /** Filter values (filter state)
     */
    filters: {
      type: Object,
      default: function () {
        return {}
      }
    },

    /** Object with accounts, fit for custom select.
     */
    accountData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      translationPath: 'transactions.view-v2.table.',
      bIsDropdownsShown: false,
      aAccountDataIncomeExpenseCustomSelect: [],
      aAccountDataAssetLiabilityCustomSelect: [],
      aFilters: {
        title: null,
        transaction_subtype: null,
        amount_min: null,
        amount_max: null,
        start_date: null,
        end_date: null,
        import_id: null,
        account_id: null,
        source_account_id: null,
        account_group_id: null,
        description: null
      }
    }
  },
  computed: {
    aTransactionSubtypes () {
      return [
        {
          label: this.$t(this.translationPath + 'labels.expense_group'),
          options: [
            { value: 'expense_all', text: this.$t(this.translationPath + 'labels.expense_all') },
            { value: 'expense_credit', text: this.$t('transactions.create.guide.expense_credit') },
            { value: 'expense_debit', text: this.$t('transactions.create.guide.expense_debit') },
            { value: 'expense_networth', text: this.$t('transactions.create.guide.expense_networth') },
            { value: 'transfer_refund', text: this.$t('transactions.create.guide.transfer_refund') }
          ]
        },
        {
          label: this.$t(this.translationPath + 'labels.income_group'),
          options: [
            { value: 'income_all', text: this.$t(this.translationPath + 'labels.income_all') },
            { value: 'income', text: this.$t('transactions.create.guide.income') },
            { value: 'income_to_liability', text: this.$t('transactions.create.guide.income_to_liability') },
            { value: 'income_networth', text: this.$t('transactions.create.guide.income_networth') }
          ]
        },
        {
          label: this.$t(this.translationPath + 'labels.asset_group'),
          options: [
            { value: 'transfer_asset_to_asset', text: this.$t('transactions.create.guide.transfer_asset_to_asset') },
            { value: 'transfer_networth_to_asset', text: this.$t('transactions.create.guide.transfer_networth_to_asset') },
            { value: 'income_asset_value_increase_capital_account', text: this.$t('transactions.create.guide.income_asset_value_increase_capital_account') },
            { value: 'asset_value_decrease_networth_account', text: this.$t('transactions.create.guide.asset_value_decrease_networth_account') },
            { value: 'asset_value_decrease_capital_account', text: this.$t('transactions.create.guide.asset_value_decrease_capital_account') },
            { value: 'asset_value_decrease', text: this.$t('transactions.create.guide.asset_value_decrease') }
          ]
        },
        {
          label: this.$t(this.translationPath + 'labels.liability_group'),
          options: [
            { value: 'liability_amortization', text: this.$t('transactions.create.guide.liability_amortization') },
            { value: 'transfer_liability_to_asset', text: this.$t('transactions.create.guide.transfer_liability_to_asset') },
            { value: 'transfer_liability_to_liability', text: this.$t('transactions.create.guide.transfer_liability_to_liability') },
            { value: 'transfer_networth_to_liability', text: this.$t('transactions.create.guide.transfer_networth_to_liability') },
            { value: 'liability_amortization_networth_account', text: this.$t('transactions.create.guide.liability_amortization_networth_account') }
          ]
        },
        {
          label: this.$t(this.translationPath + 'labels.other_group'),
          options: [
            { value: 'complex_transaction', text: this.$t('transactions.create.guide.complex_transaction') }
          ]
        }
      ]
    }
  },
  methods: {
    clear (filter) {
      this.aFilters[filter] = null
      this.$emit('clear', filter)
    },
    update (filter) {
      switch (filter) {
        case 'account_id':
          this.aFilters.account_id = this.aFilters.account_id.value.value
          break
        case 'source_account_id':
          this.aFilters.source_account_id = this.aFilters.source_account_id.value.value
          break
        case 'amount' :
          if (this.aFilters.amount_min === '') { this.aFilters.amount_min = null }
          if (this.aFilters.amount_max === '') { this.aFilters.amount_max = null }
          break
      }

      this.$emit('update', this.aFilters)
    },
    getAmountLabelBasedOnAmounts () {
      if (this.aFilters.amount_min !== '' && this.aFilters.amount_max !== '' && this.aFilters.amount_min !== null && this.aFilters.amount_max !== null) {
        return 'both'
      } else if (this.aFilters.amount_min && (this.aFilters.amount_max === '' || this.aFilters.amount_max === null)) {
        return 'min'
      } else if (this.aFilters.amount_max && (this.aFilters.amount_min === '' || this.aFilters.amount_min === null)) {
        return 'max'
      }
      return 'none'
    },
    translateAccountId (v) {
      if (this.accountData.allAccounts !== null) {
        const aAccount = this.accountData.allAccounts.filter(function (item) {
          return parseInt(item.id) === parseInt(v)
        }, v)
        if (aAccount.length >= 1) {
          return aAccount[0].label
        }
      }
      return ''
    },
    translateAccountGroupId (v) {
      if (this.accountData.allAccounts !== null) {
        const aAccount = this.accountData.allAccountGroups.filter(function (item) {
          return parseInt(item.id) === parseInt(v)
        }, v)
        if (aAccount.length >= 1) {
          return aAccount[0].label
        }
      }
      return ''
    },
    translateTransactionType (v) {
      switch (v) {
        case 'income_all':
        case 'expense_all':
          return this.$t(this.translationPath + 'labels.' + v)
        case '' :
        case null :
          return ''
        default:
          return this.$t('transactions.create.guide.' + v)
      }
    },
    loadCustomSelectIncomeExpenseAccounts () {
      if (!Object.prototype.hasOwnProperty.call(this.accountData, 'incomeGroups')) {
        return false
      }

      this.aAccountDataIncomeExpenseCustomSelect = [
        { label: this.$t('common.incomes'), accounts: [], groupAccounts: this.accountData.incomeGroups },
        { label: this.$t('common.expenses'), accounts: [], groupAccounts: this.accountData.expenseGroups }
      ]
    },
    loadCustomSelectAssetLiabilityAccounts () {
      if (!Object.prototype.hasOwnProperty.call(this.accountData, 'assetGroups')) {
        return false
      }

      this.aAccountDataAssetLiabilityCustomSelect = [
        { label: this.$t('common.assets'), accounts: [], groupAccounts: this.accountData.assetGroups },
        { label: this.$t('common.liabilities'), accounts: [], groupAccounts: this.accountData.liabilityGroups }
      ]
    }
  },
  watch: {
    showDropdowns (v) {
      this.bIsDropdownsShown = v
    },
    filters (v) {
      this.aFilters = { ...v }
    }
  }
}
</script>
